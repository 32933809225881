import cn from 'classnames';
import React from 'react';

import s from './styles.module.css';

export enum CONTENT_AREA_TYPES {
  header = 'header',
  main = 'main',
}

export type MainContentType = React.FC<{
  children: React.ReactNode;
  area?: CONTENT_AREA_TYPES;
}>;

/**
 * Обертка для основного контента в ColumnLayout
 * @param area – область которую будет занимать контент, задается в css grid-template-areas.
 * В основном нужен чтобы управлять галереей в кластере. На больших разрешенияхх она должна растягиваться на 2 колонки
 */
export const MainContent: MainContentType = ({
  children,
  area = CONTENT_AREA_TYPES.main,
  ...otherProps
}) => (
  <div className={cn(s.mainContent, s[`${area}Area`])} {...otherProps}>
    {children}
  </div>
);
