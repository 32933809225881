import cn from 'classnames';
import React from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import { MainContent, MainContentType } from './MainContent';
import { NarrowColumn, NarrowColumnType } from './NarrowColumn';

import s from './styles.module.css';

/**
 * Оставила тут React.FC, т.к. пока не нашла дуругого решения, которое работало бы таким же образом.
 * Если оборачивать в memo вылезают ошибки типизации, которые не дают использовать MainContent и NarrowColumn.
 */
type ColumnsLayoutType = React.FC<{
  children: React.ReactNode;
  isWideHeader?: boolean;
  isMulticluster?: boolean;
}> & {
  MainContent: MainContentType;
  NarrowColumn: NarrowColumnType;
};

/**
 * Лэйаут с 3 колонками
 * @param isWideHeader – флаг что area header в css растягивается на 2 колонки;
 * @param isMulticluster - флаг, что это мультикластер.
 */
export const ColumnsLayout: ColumnsLayoutType = ({
  children,
  isWideHeader = false,
  isMulticluster = false,
}) => {
  const top100AttributeMulticluster =
    useTop100AttributeWithValue('multi_cluster');
  const top100Attribute = isMulticluster ? top100AttributeMulticluster : '';

  return (
    <div
      className={cn(s.root, isWideHeader && s.rootWideHeader)}
      {...top100Attribute}
    >
      {children}
    </div>
  );
};

ColumnsLayout.MainContent = MainContent;
ColumnsLayout.NarrowColumn = NarrowColumn;
