import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { selectPagePuids } from 'common/redux/selectors';
import Banners from 'config/constants/banner/banners';
import { PROJECT_IDS } from 'config/constants/projects/constants';

import 'desktop/css/banner--native-article.css';
import 'desktop/css/banner--nativefooter.css';
import 'desktop/css/banner--native-partner.css';
import 'desktop/css/banner--native-partner-right-column.css';
import 'desktop/css/banner--native-in-recs.css';
import 'desktop/css/banner--native-context_card.css';
import 'desktop/css/banner--native-context_list.css';
import 'desktop/css/banner--sponsor.css';

type LayoutPropsType = {
  children: React.ReactNode;
};

/**
 * Обертка страницы
 * @param props.children - контент.
 */
export const Layout = withErrorBoundary(function Layout({
  children,
}: LayoutPropsType) {
  const projectId = useSelector(selectProjectId);
  const puids = useSelector(selectPagePuids, shallowEqual);

  return (
    <>
      {children}
      {projectId === PROJECT_IDS.News && (
        <Ad name={Banners.Storoni} puids={puids} />
      )}
    </>
  );
});
