import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { Ad } from 'common/components/Ad';
import {
  AdLoadingState,
  useAdLoadStatus,
} from 'common/components/Ad/hooks/useAdLoadStatus';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import { ItemSkeleton } from './ItemSkeleton';

import s from './styles.module.css';

import 'desktop/css/banner--native-partner_widget.css';
import 'desktop/css/banner--native-footer_widget.css';
import 'desktop/css/banner--native-in-recs_widget.css';

type NativeNewsWidgetPropsType = {
  puids: PuidsType | undefined;
  clusterIndex?: number;
};

/**
 * Виджет с новостями NativePartner и Nativefooter (или NativeInRecs)
 * @param puids - рекламные пуиды
 * @param clusterIndex - индекс кластера
 */
export const NativeNewsWidget = withErrorBoundary(function NativeNewsWidget({
  puids,
  clusterIndex = 0,
}: NativeNewsWidgetPropsType) {
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const [isNativePartnerLoaded, setIsNativePartnerLoaded] = useState(false);
  const [isNativeFooterLoaded, setIsNativeFooterLoaded] = useState(false);

  const { handleComplete, adStatus } = useAdLoadStatus();

  const handleCompleteNativePartner = useCallback(() => {
    setIsNativePartnerLoaded(true);
  }, []);

  const handleCompleteNativeFooter = useCallback(() => {
    setIsNativeFooterLoaded(true);
  }, []);

  const isFirstCluster = clusterIndex === 0;

  return (
    <aside className={cn(s.nativeWidget, forceRedesign && s.redesign)}>
      <div className={s.adContainer}>
        <Ad
          name={Banners.NativePartner}
          puids={puids}
          className={cn(
            'banner--native-partner_widget',
            !isNativePartnerLoaded && s.hidden,
          )}
          withoutMarginBottom
          disablePlaceholder
          onComplete={handleCompleteNativePartner}
        />

        {!isNativePartnerLoaded && <ItemSkeleton />}
      </div>

      {(isFirstCluster || adStatus === AdLoadingState.Error) && (
        <div className={s.adContainer}>
          <Ad
            name={Banners.Nativefooter}
            puids={puids}
            disablePlaceholder
            className={cn(
              s.nativeWidgetItem,
              'banner--native-footer_widget',
              !isNativeFooterLoaded && s.hidden,
            )}
            withoutMarginBottom
            onComplete={handleCompleteNativeFooter}
          />

          {!isNativeFooterLoaded && <ItemSkeleton />}
        </div>
      )}

      {!isFirstCluster && adStatus !== AdLoadingState.Error && (
        <div className={s.adContainer}>
          <Ad
            name={Banners.NativeInRecs}
            puids={puids}
            disablePlaceholder
            className={cn(
              s.nativeWidgetItem,
              'banner--native-in-recs_widget',
              adStatus === AdLoadingState.Unloaded && s.hidden,
            )}
            withoutMarginBottom
            onComplete={handleComplete}
          />

          {adStatus === AdLoadingState.Unloaded && <ItemSkeleton />}
        </div>
      )}
    </aside>
  );
});
