import React, { useMemo, memo } from 'react';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';

import s from './styles.module.css';

const ORIGINAL_URL_PARAM = 'orig_url';
const NEW_IMAGE_SIZE = '1x1';
const IMAGE_SIZE_REGEXP = /\d{1,3}x\d{1,3}/gi;

type PixelImagePropsType = {
  image: APIResourceRnetBlockBanner['logo'];
};

/**
 * Компонент пикселя изображения для сбора рекомендациями статистики.
 * @param props.image - ссылка на изображение кластера.
 */

export const PixelImage = memo(({ image }: PixelImagePropsType) => {
  const compressedImage = useMemo(() => {
    try {
      const tempImageUrl = new URL(image);
      const originalRnetUrl =
        tempImageUrl.searchParams?.get(ORIGINAL_URL_PARAM);

      if (!originalRnetUrl) return image;

      /*
        В ссылке есть пропс с оригинальной ссылкой на изображение, в которой прописан размер картинки
          Его и подменяем
       */
      const changedOriginalRnetUrl = originalRnetUrl.replace(
        IMAGE_SIZE_REGEXP,
        NEW_IMAGE_SIZE,
      );

      tempImageUrl.searchParams.set(ORIGINAL_URL_PARAM, changedOriginalRnetUrl);

      return tempImageUrl.toString();
    } catch {
      return image;
    }
  }, [image]);

  return (
    <RamblerImage
      className={s.pixelImage}
      width={1}
      height={1}
      src={compressedImage}
      isS3={false}
      isLazy={false}
    />
  );
});
