import cn from 'classnames';
import React, { memo } from 'react';

import { RamblerImageBg } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { PlaceholderIcon } from 'icons';

import { PixelImage } from '../PixelImage';

import s from './styles.module.css';

type ItemPropsType = {
  url: ATResourceRnetBlockBanner['url'];
  title: APIResourceRnetBlockBanner['title'];
  image: APIResourceRnetBlockBanner['logo'];
  index: number;
  favicon: string;
};

/**
 * Компонент новости для виджета новостей источника.
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.image - изображение кластера;
 * @param props.index - индекс айтема для топ100;
 * @param props.favicon - ссылка на иконку источника.
 */

export const Item = memo(
  ({ url, title, image, index, favicon }: ItemPropsType) => {
    const top100AttributeWithValue = useTop100AttributeWithValue(
      `item::${index + 1}`,
    );
    const isRamblerSource = url?.includes('rambler.ru');

    return (
      <li className={s.item}>
        <a
          href={url}
          {...top100AttributeWithValue}
          target="_blank"
          rel={`noopener noreferrer${isRamblerSource ? ' nofollow' : ''}`}
        >
          {favicon ? (
            <RamblerImageBg
              className={s.image}
              width={15}
              height={15}
              src={favicon}
              isLazy
            />
          ) : (
            <PlaceholderIcon className={cn(s.image, s.icon)} />
          )}
          <PixelImage image={image} />
          <p className={s.title}>{title}</p>
        </a>
      </li>
    );
  },
);
