import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectForceAdFallback } from 'common/redux/runtime/selectors';
import { defaultCallback } from 'config/constants/common';

import { IResult } from '../typings';

/**
 * Мапка со статусами загрузки рекламы рекоммендов.
 * Если рекомменды не загрузились - круто, рисуем что-то ещё.
 */
export enum AdLoadingState {
  // Статус - реклама еще не грузилась
  Unloaded = 'unloaded',
  // Статус - реклама успешно загрузилась
  Loaded = 'loaded',
  // Статус - реклама не загрузилась :с
  Error = 'error',
}

type OnCompleteCallback = (res: IResult) => void;

type useAdLoadStatusType = (onComplete?: OnCompleteCallback) => {
  handleComplete: OnCompleteCallback;
  adStatus: AdLoadingState;
};

/**
 * Хук, отлавливающий процесс загрузки баннера и возвращающий соответствующий статус.
 * Если баннер никак не уведомил о своем статусе загрузки, считается, что баннер загрузился.
 * @param OnRender - функция для отслеживания рендера;
 * @returns {
 *   onComplete - обернутая функция, которую нужно передать в рекламный компонент;
 *   adStatus - статус загрузки рекламного компонента.
 * }
 */
export const useAdLoadStatus: useAdLoadStatusType = (
  _onComplete = defaultCallback,
) => {
  const [adStatus, setAdStatus] = useState<AdLoadingState>(
    AdLoadingState.Unloaded,
  );
  const forceAdFallback = useSelector(selectForceAdFallback);

  const handleComplete = useCallback<OnCompleteCallback>(
    (res) => {
      if (_onComplete) _onComplete(res);

      if (!res || typeof res.status !== 'boolean')
        return setAdStatus(AdLoadingState.Loaded);

      return setAdStatus(
        res.status ? AdLoadingState.Loaded : AdLoadingState.Error,
      );
    },
    [_onComplete],
  );

  return {
    handleComplete,
    adStatus: forceAdFallback ? AdLoadingState.Error : adStatus,
  };
};
