import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { NarrowColumnContext } from './context';

import s from './styles.module.css';

export enum NARROW_AREA_TYPES {
  widgets = 'widgets',
  aside = 'aside',
}

export type NarrowColumnType = React.FC<{
  children: React.ReactNode;
  isSticky?: boolean;
  needAddProps?: boolean;
  area?: NARROW_AREA_TYPES;
  className?: string;
}>;

/**
 * Дополнительная колонка, для виджетов или рекламы
 * @param isSticky – флаг липкого контента
 * @param needAddProps – флаг добавления дополнительных пропсов
 * @param area - область которую будет занимать контент, задается в css grid-template-areas.
 * @param className - строка с классом для дополнительной стилизации.
 * В основном нужен чтобы управлять галереей в кластере. На больших разрешенияхх она должна растягиваться на 2 колонки
 */
export const NarrowColumn: NarrowColumnType = ({
  children,
  isSticky = false,
  needAddProps = false,
  className,
  area = NARROW_AREA_TYPES.aside,
}) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [fullHeight, setFullHeight] = useState(0);

  useEffect(() => {
    if (!needAddProps) return;

    // Вычисляем свободное место в колонке, чтобы передавать в виджеты и там определять умещается ли виджет подписки
    const columnHeight = columnRef.current?.clientHeight ?? 0;

    setFullHeight(columnHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnRef.current?.clientHeight, needAddProps]);

  const value = useMemo(() => ({ fullHeight }), [fullHeight]);

  return (
    <NarrowColumnContext.Provider value={value}>
      <div
        className={cn(
          s.narrowColumn,
          className,
          isSticky && s.sticky,
          s[`${area}Area`],
        )}
        ref={columnRef}
      >
        <div>{children}</div>
      </div>
    </NarrowColumnContext.Provider>
  );
};
