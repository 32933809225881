import cn from 'classnames';
import React, { forwardRef, memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectClusterRelated } from 'common/redux/commonData/entries/selectors';
import { selectResourceById } from 'common/redux/commonData/resources/selectors';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import {
  RESOURCES_WITH_RNET,
  RESOURCES_EXCLUDED_FOR_REDESIGN,
} from 'config/constants/resourceRnet';
import { Item } from 'desktop/components/Cluster/RnetNewsWidget/components/Item';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';
import { WIDGET_TYPE } from 'desktop/components/FeedWidgetLayout/constants';
import { useAppSelector } from 'store/hooks';

import { RcmWidget } from '../..';
import { WidgetLayoutTypeV4, WidgetTemplate } from '../../typings';

import s from './styles.module.css';

type RcmWidgetWithResourcePropsType = {
  resourceId: RESOURCES_WITH_RNET | undefined;
  clusterId: CardData['id'] | null;
};

/**
 * Виджет реккомендов при рендере c resourceId.
 * @param resourceId - id источника кластера;
 * @param clusterId - id кластера, для которого получаем рекомменды.
 */
export const RcmWidgetWithResource = memo(
  forwardRef<HTMLDivElement, RcmWidgetWithResourcePropsType>(
    ({ resourceId, clusterId }, ref) => {
      const resource = useSelector(
        selectResourceById(resourceId),
        shallowEqual,
      );

      const related = useAppSelector(
        selectClusterRelated(clusterId),
        shallowEqual,
      );

      const forceRedesign = useSelector(
        selectIsHumanCenteredStrategyStateActivated,
      );

      const isRamblerResource = resourceId
        ? RESOURCES_EXCLUDED_FOR_REDESIGN.includes(resourceId)
        : false;

      const widgetTitle = resource.title
        ? `${resource.title}: главные новости`
        : 'Последние новости';

      const news = useMemo(
        () =>
          related?.map((cluster, index) => ({
            url: cluster.url,
            title: cluster.title,
            bannerId: index,
            logo: cluster.imageUrl,
          })) || [],
        [related],
      );

      const isRenderRcm = resourceId
        ? Object.values(RESOURCES_WITH_RNET).includes(resourceId)
        : false;

      const isExcludedFromRedesign =
        resourceId &&
        forceRedesign &&
        RESOURCES_EXCLUDED_FOR_REDESIGN.includes(resourceId);

      if (isRenderRcm && !isExcludedFromRedesign) {
        return (
          <div className={cn(s.widget, forceRedesign && s.redesign)}>
            <RcmWidget
              resourceId={resourceId}
              templateName={WidgetTemplate.v4}
              layoutType={WidgetLayoutTypeV4.Vertical}
              clusterId={clusterId}
            />
          </div>
        );
      }

      // Ничего не рендерим, если нет последних новостей источника кластера
      if (news.length === 0) {
        return null;
      }

      // В редизайне скрываем виджеты, если кластер от нашей редации
      if (forceRedesign && isRamblerResource) {
        return null;
      }

      return (
        <FeedWidgetLayout
          ref={ref}
          widgetType={WIDGET_TYPE.rnet}
          title={widgetTitle}
          top100Value="rnet_widget::related"
          className={cn(forceRedesign && s.redesign)}
        >
          {news.length !== 0 &&
            news.map((cluster, index) => {
              const { url, title, bannerId, logo } = cluster;

              return (
                <Item
                  key={bannerId}
                  url={url}
                  title={title}
                  image={logo}
                  index={index}
                  favicon={resource.favicon}
                />
              );
            })}
        </FeedWidgetLayout>
      );
    },
  ),
);
