import React, { memo } from 'react';

import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { RcmWidgetWithResource } from 'common/components/RCMWidget/components/RcmWidgetWithResource';
import { TopMainNewsWidget } from 'desktop/components/TopNewsWidget';

type WidgetGroupWithSubscribePropsType = {
  resourceId: ClusterData['resourceId'];
  clusterId: ClusterData['id'] | null;
  level?: number;
  isMulticluster?: boolean;
};

/** Отступ между блоками */
// const GAP = 20;

/**
 * Группа виджетов с виджетом подписки
 * @param resourceId – id источника кластера
 * @param clusterId - id кластера, на котором располагается виджет
 * @param isMulticluster - флаг, что это мультикластер
 * @param level - этаж на котором отображаются виджеты
 */
export const WidgetGroupWithSubscribe = memo(
  ({
    resourceId,
    clusterId,
    isMulticluster,
    level,
  }: WidgetGroupWithSubscribePropsType) => {
    // const { fullHeight } = useContext(NarrowColumnContext);
    // const rnetNewsWidgetRef = useRef(null);
    // const topProjectNewsWidgetRef = useRef(null);

    // const rnetNewsWidgetHeight =
    //   rnetNewsWidgetRef.current?.clientHeight ?? RNET_WIDGET_HEIGHT_DEFAULT;
    // const topProjectNewsWidgetHeight =
    //   topProjectNewsWidgetRef.current?.clientHeight ?? TOP_NEWS_WIDGET_HEIGHT_DEFAULT;

    // const isFitsSubscribeWidget =
    //   SUBSCRIBE_WIDGET_FULL_HEIGHT <=
    //   fullHeight - rnetNewsWidgetHeight - topProjectNewsWidgetHeight - GAP * 2;

    return (
      <>
        <ErrorBoundary componentName="TopMainNewsWidget">
          <TopMainNewsWidget level={level} />
        </ErrorBoundary>
        {/* Показываем виджет подписки только если есть свободное место в колонке, тобишь если кластер длинный */}
        {/* Временно скрыт NEWS-11312 */}
        {/* <ErrorBoundary> */}
        {/* {isFitsSubscribeWidget && <SubscribeFormWidget />} */}
        {/* </ErrorBoundary> */}
        <ErrorBoundary componentName="RcmWidgetWithResource">
          {!isMulticluster && (
            <RcmWidgetWithResource
              resourceId={resourceId}
              clusterId={clusterId}
            />
          )}
        </ErrorBoundary>
      </>
    );
  },
);
